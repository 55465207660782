import * as React from 'react'
import {useCallback, useEffect, useState} from 'react'

import {useKeycloak} from '@react-keycloak/web'
import {BrowserRouter as Router} from "react-router-dom";
import {connect} from "react-redux";
import {isAuthorized, storeKeycloakToken} from "../../utils";
import LoginProfile from "./LoginProfile";
import {changeInitState, setUserInfo} from "../../reducers";

function Login(props) {

    // const [userInfo, setUserInfo] = useState({});

    const {keycloak, initialized} = useKeycloak();
    const [keycloakTokenRefreshInterval, setKeycloakTokenRefreshInterval] = useState();

    if (keycloak && keycloak.authenticated) {
        storeKeycloakToken(keycloak.token);
    }

    if (keycloak && initialized)
        props.changeInitState(true);

    useEffect(() => {
        if (keycloak && keycloak.authenticated)
            keycloak.loadUserProfile().then(info => {
                let admin = isAuthorized(["Admin_Webshop"], keycloak);
                props.setUserInfo(info, admin)
            }).catch(err => {
                console.error("error in loading user profile. ", err)
            });
    }, [keycloak, keycloak.authenticated])


    const renewKeycloakToken = () => {
        keycloak.updateToken(120).then(response => {
            if (response) {
                storeKeycloakToken(keycloak.token);
                clearInterval(keycloakTokenRefreshInterval)
            }
        }).catch(err => {
            console.error("error in updating keycloak token", err);
        })
    }

    useEffect(() => {
        let interval = setInterval(renewKeycloakToken, 60 * 1000)
        setKeycloakTokenRefreshInterval(interval);
        return () => {
            clearInterval(keycloakTokenRefreshInterval)
        }
    }, [keycloak])

    const login = useCallback(() => {
        if (keycloak)
            keycloak.login()
    }, [keycloak])

    function getLoginComponent() {

        if ((keycloak && keycloak.authenticated))
            return <LoginProfile keycloak={keycloak}/>
        return <button type="button" className={"default-lbk-button login-button"} onClick={(event) => {
            event.preventDefault();
            window.dataLayer.push({
                event: 'login',
                event_category: 'login',
            });
            login()
        }}>
            <span className={"hidden sm:block"}>
            Logg inn
        </span>
            <span className={"sm:hidden h-6 w-6"}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                 stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round"
        d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"/>
                </svg>


        </span>
        </button>
    }

    return (
        <div>
            <Router basename={process.env.REACT_APP_BASENAME}>
                {getLoginComponent(props.userInfo)}
            </Router>
        </div>
    )
}

const mapStateToProps = ({userInfo}) => {
    return {userInfo: userInfo}
}
export default connect(mapStateToProps, {setUserInfo, changeInitState})(Login)