import {Link} from "react-router-dom";
import ImageThumbnail from "../Shared/ImageThumbnail";
import {convertNumberToLocale} from "../../utils";

export default function ProductCard({product, reload, crmPerson}) {
    function showPriceForB2C(product) {
        if (product.hidePriceForB2C && Object.keys(crmPerson).length === 0)
            return false;
        if (product.hidePriceForB2C && crmPerson && crmPerson.customerType === "B2B")
            return true;
        if (!product.hidePriceForB2C)
            return true;
    }

    return (
        <Link className={"hover:shadow-2xl duration-200 hover:-translate-y-2"} to={`/shop/product/${product.id}`}
              reloadDocument={reload}>
            <div key={product.id}
                 className={"flex flex-col gap-2 h-full bg-white"}>
                <div className="flex h-80 w-full items-center justify-center rounded-md bg-gray-200">
                    <ImageThumbnail image={product.productImages.length > 0 ? product.productImages[0] : null}/>

                </div>

                <div className="flex flex-col grow justify-between p-2">
                    <h3 className="text-sm text-gray-700">
                        {product.name}
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">{product.no}</p>
                    {product.stock > 0 &&
                        <p className="mt-1 text-sm text-gray-500 flex items-center gap-2">På lager:
                            <span className={"flex items-center gap-1"}><img
                                className="h-6"
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEqUlEQVR4nO2Z20+aZxzH3/ZiHmACShFQDjarnduF+wOWmEjVeqoKyFFRwHTJbvYnkIjDQ1cQFZSqYFtbrIebJo12m96ZLNnabBfatOnU9GKRddU168WatfkuD7Or1sPLC7zYC77Jc/1+vg+/53l+vy8UlVZaaSVdirC1Wh5uW5CF27cLw5Zt2U3LQsGkpZp636UI20vkt6x35FPtkIfbIQu3QXbTgsIbFhROtkJ63bwonTR9dtycVOG0Pbdo1t6jnLE9UMzYnyunbVBMW6G4ZcVh8AXXW/5b18yQXjVDOmGCJGR4LgkaV8VBQ7fcbxKkBP709EV50VzHhnLWDuWMHXHDTxghCRkhDhogHjdANKpblwb1MnbpHY6Tyjn7crLh88f0yB/TQTTavEy+wRp/0WyHnT14HURXmiEMaGyswBffvihUzNgibMKLAloIA9qIJGAUJo8c1AnlXIdFMWPbZBv+FFkjGgj96mdCn/qrhMuJHFh2a163H344agB5vibkDTUtC3wNsvivShZum5jhfU3IHWpE7mDjGs9fy/yKLZrr6H0P4CEYaIBg4IKLsQHySKUEflgDoU8N4Q74AfDg99evxGHA/oJ1eL8aEo8aX9zuw2mvHnmDjfvgBd4L4Hnq/mJsQDFte8k2vNjdhPlHP4Do0R9PcHbQjFxvwx54fn89+O66v+MwYH3F6s671fju8Y/Yrfu/PQS/t2YvvKcOPHfdP4zglVPW0lTDvzVQ/S48eO5a8C/Xlsa++1PWb2nhr5ogDZogDcVfNrv18OkTFHsNEHjq98HnXK7Fh5eql2IfRujgQyZ8PNaOL+fdkI0YIBk3JLTzq5F1fNSvPxQ+55saYoAs+qGITFJ08J+O2bD6dCP68aWN+5D5dBCP6lnZ+Zy38OD2nV+gNUDGwKNqXuxvxi+bj/dAfL/2EwoGm5FPoNnZeXD7zoPTW/UnrYGdGfbQAyv2afHzOwbemJAOaCEa0bICz+2tAqencov+F7jROn/kbTNuwCej7f+X0G4trd1DgVcTfV2TWDYg8NyeKnC6K+ZpDZD0gPaqHNOjJNB2oInFtXuQ9mtYgK9Edk9FbMlGwbWWu7T3/BUdSgKWA02QkklW2XDfwLsqF2OC3zFQGtMjFWjGWX8rVn9f3webzJ3nEAPdlbE/ZETSCfOrmF7YYQ2KfS1YOcJEwvCuCmatBJEkZHrJ5IUtHjJjJbLOBjyyu1TMmzlJyPCC0TAy1IQzA8Y9JpIC//U5ZHWdY95Oi4OGB4wnqcFGnPEao00ZWfEeWM5eeGR1qpgPNCTui2sM9DZEW+JoV5kMeKcKGU5VF2MDJKskcV+cM+yBLXE88JnO8l953Z/Hl5uSrJLEfccFn9GpWs5ylBVSCQnUiVPDWotwRLOZKvhMZ/mzDzrLEw+2dovEfXnDmkgK4CNcR1kSo8VdEvrU9hSUjY1iTQ7HSRL3sVnzFJvxOhHJKkncx8Ztk5XwgY1RJKskcR9JzPie+i2+p/Y1c3jV60ynaos8UhnOchfPUcanjltcT01JzqWaO0e1xNldFcf/Jx+dSHpABnBOT9U2p7tqm+OqXIh5GEkrrbQoJvoXZTdWgBhNh8sAAAAASUVORK5CYII="
                                alt="verified-account--v1"/>
                            </span></p>}
                    {(product.stock < 1 && product.stockControlled !== null && product.stockControlled) &&
                        <p className="mt-1 text-sm text-white flex"><span
                            className={"bg-red-300 px-2 rounded"}>Utsolgt</span></p>}
                    {(product.price === 0 || (product.stock < 1 && product.stockControlled !== null && !product.stockControlled)) &&
                        <p className="mt-1 text-sm text-white flex">
                            <span className={"bg-blue-500 p-2 rounded"}>Bestillingsvare</span>
                        </p>}
                    {product.purchaseAbleOnWeb && showPriceForB2C(product) && product.price > 0 &&
                        <p className="flex flex-col text-sm font-bold text-gray-900">
                            {product.discountedPrice > 0 && <span
                                className={"text-red-500 text-right"}>Kr {convertNumberToLocale(product.discountedPrice)}</span>}
                            <span
                                className={`text-right ${product.discountedPrice > 0 ? "line-through" : ""}`}>Kr {convertNumberToLocale(product.price)}</span>
                        </p>}
                </div>
            </div>
        </Link>
    )
}