import {connect} from "react-redux";
import {QuestionMarkCircleIcon} from '@heroicons/react/20/solid'
import {Link} from "react-router-dom";
import {calculateVAT, convertNumberToLocale, getTotalWithVAT} from "../../utils";
import {TrashIcon} from "@heroicons/react/24/solid";
import {addProductToCart, changeProductQuantityInCart, removeProductFromCart} from "../../reducers";
import {MinusIcon, PlusIcon} from "@heroicons/react/24/outline";
import {useFetchHighlightedProducts} from "../../utils/UseFetchConstants";
import {useEffect} from "react";

function CartDetails(props) {

    let subtotal = 0;

    function calculateSubTotal(qty, price) {
        subtotal = subtotal + (qty * price);
        return null;
    }

    const {products} = useFetchHighlightedProducts(props.popularProducts)

    useEffect(() => {
        window.dataLayer.push({
            event: 'view_cart',
            event_category: 'ecommerce',
            products: props.cart.map(item => item.product)
        });

    }, [props.cart])

    return (
        <div className="bg-white py-4">
            <div className="mx-auto max-w-2xl px-4 pt-16 pb-24 sm:px-6 lg:max-w-7xl lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">HANDLEKURVEN</h1>
                <div className="mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16">
                    <section aria-labelledby="cart-heading" className="lg:col-span-7">
                        <h2 id="cart-heading" className="sr-only">
                            Items in your shopping cart
                        </h2>

                        <ul role="list" className="divide-y divide-gray-200 border-t border-b border-gray-200">
                            {props.cart.map((item, productIdx) => (
                                <li key={item.product.id} className="flex py-6 sm:py-10">
                                    <div className="flex-shrink-0">
                                        <img
                                            src={item.product.productImages.length > 0 ? `${process.env.REACT_APP_API_ENDPOINT}/attachment/${item.product.productImages[0].id}/thumbnail` : 'https://www.anleggsdeler.no/static/img/defaultimagelbt-01.jpg'}
                                            alt={item.product.name}
                                            className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                                        />
                                    </div>
                                    <div className="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                                        <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                                            <div>
                                                <div className="flex justify-between">
                                                    <h3 className="text-sm">
                                                        <Link to={`/shop/product/${item.product.id}`}
                                                              className="font-medium text-gray-700 hover:text-gray-800">
                                                            {item.product.name}
                                                        </Link>
                                                    </h3>
                                                </div>
                                                <p className="mt-1 text-sm font-medium text-gray-900"><span
                                                    className={"text-gray-500"}>Kr </span>{convertNumberToLocale(item.product.price)}
                                                </p>
                                                <div className="mt-1 flex gap-2 text-sm font-medium text-gray-900"><span
                                                    className={"text-gray-500"}>Antal </span>
                                                    {item.quantity}
                                                    <div className="flex divide-x rounded-md border">
                                                        <div
                                                            className="flex items-center justify-center px-2 border-r cursor-pointer"
                                                            onClick={() => props.changeProductQuantityInCart(item.product.id,item.quantity - 1)}
                                                        >
                                                            <span className="text-gray-500 sm:text-xs">
                                                                <MinusIcon className={"h-4 w-4"}/>
                                                            </span>
                                                        </div>
                                                        <div className="flex items-center justify-center px-2 border-l cursor-pointer"
                                                             onClick={() => props.changeProductQuantityInCart(item.product.id,item.quantity + 1)}
                                                        >
                                                            <span className="text-gray-500 sm:text-xs" id="price-currency">
                                                                <PlusIcon className={"h-4 w-4"}/>
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>
                                                {calculateSubTotal(item.quantity, item.product.price)}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <TrashIcon className={"h-5 text-red-500 cursor-pointer hover:text-lbkGreen"}
                                                    onClick={() => {
                                                        props.removeProductFromCart(item.product.id)
                                                    }}/>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </section>

                    {/* Order summary */}
                    <section
                        aria-labelledby="summary-heading"
                        className="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8"
                    >
                        <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
                            TOTALT I HANDLEKURVEN
                        </h2>

                        <dl className="mt-6 space-y-4">
                            <div className="flex items-center justify-between">
                                <dt className="text-sm text-gray-600">Delsum</dt>
                                <dd className="text-sm font-medium text-gray-900">kr {convertNumberToLocale(subtotal)}</dd>
                            </div>
                            <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                                <dt className="flex text-sm text-gray-600">
                                    <span>MVA.</span>
                                    <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Learn more about how tax is calculated</span>
                                        <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true"/>
                                    </a>
                                </dt>
                                <dd className="text-sm font-medium text-gray-900"><span
                                    className={"text-xs font-medium text-gray-500"}>(kr {calculateVAT(subtotal)})</span> 25%
                                </dd>
                            </div>
                            <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                                <dt className="text-base font-medium text-gray-900">Totalt</dt>
                                <dd className="text-base font-medium text-gray-900">kr {getTotalWithVAT(subtotal)}</dd>
                            </div>
                        </dl>

                        <div className="mt-6">
                            <Link
                                to={"/shop/checkout"}
                                className="default-lbk-button w-full max-w-full"
                            >
                                Til Betaling
                            </Link>
                        </div>
                        {products && products.length > 0 &&
                            <div className={"mt-6"}>
                                {products.map(product =>
                                    <div key={product.id}
                                        className="max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden my-4">
                                        <div
                                            className="bg-gradient-to-r from-lbkGreen via-red-500 to-gray-500 text-black px-6 py-2 flex justify-between items-center">
                                            <h2 className="text-white text-lg font-semibold">Populært produkt</h2>
                                        </div>
                                        <div className="sm:flex sm:items-center px-6 py-4">
                                            {product.productImages.length > 0 ? <img
                                                className="block mx-auto sm:mx-0 sm:flex-shrink-0 h-24 w-24 sm:h-24 sm:w-24 object-cover object-center rounded transition-transform duration-500 ease-in-out transform hover:scale-105"
                                                src={`${process.env.REACT_APP_API_ENDPOINT}/attachment/${product.productImages[0].id}/thumbnail`} alt={`${product.name}`}/>
                                                :
                                                <img
                                                className="block mx-auto sm:mx-0 sm:flex-shrink-0 h-24 w-24 sm:h-24 sm:w-24 rounded transition-transform duration-500 ease-in-out transform hover:scale-105"
                                                src="https://via.placeholder.com/150" alt="Product Name"/>}
                                            <div className="mt-4 sm:mt-0 sm:ml-4 text-center sm:text-left">
                                                <Link to={`/shop/product/${product.id}`} className="text-xl leading-tight  hover:underline hover:cursor-pointer">{product.name}</Link>
                                                <p className="text-sm leading-tight text-gray-600">{product.description}</p>
                                                <div className="mt-4">
                                                    <button
                                                        className="bg-lbkGreen text-black hover:text-lbkGreen-500 hover:bg-gray-500  border border-black-500 text-xs font-semibold rounded-full px-4 py-1 leading-normal transition-colors duration-200"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            props.addProductToCart(product, 1);
                                                        }}
                                                    >Legg
                                                        til - kr {convertNumberToLocale(product.price)},-
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )}
                            </div>}

                    </section>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = ({cart}) => {
    return {cart: cart}
}
export default connect(mapStateToProps, {removeProductFromCart,addProductToCart,changeProductQuantityInCart})(CartDetails);