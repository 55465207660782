import {Link} from "react-router-dom";
import React from "react";

export default function CatalogNodeCard({node}) {
    return <div key={node.id} className="group rounded shadow relative hover:shadow-2xl hover:-translate-y-2 duration-200">
        <div className="aspect-w-1 aspect-h-1 rounded-t overflow-hidden bg-gray-200 group-hover:opacity-75">
                            <img
                    src={node.cardImage ? `${process.env.REACT_APP_API_ENDPOINT}/attachment/${node.cardImage.id}/thumbnail` : 'https://www.anleggsdeler.no/static/img/defaultimagelbt-01.jpg'}
                    alt={node.cardImage ? node.cardImage.name : ''}
                    className="h-full w-full object-cover object-center"
                />

        </div>
        <div className="pt-5 pb-4 text-center">
            <h3 className="text-sm font-medium text-gray-900">
                <Link to={`/shop/category/${node.id}`}>
                    <span aria-hidden="true" className="absolute inset-0"/>
                    {node.name}
                </Link>
            </h3>
        </div>
    </div>
}