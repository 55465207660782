import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./store";
import {Provider} from "react-redux";
import HighlightedProductsWidget from "./components/Widget/HighlightedProductsWidget";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak"
import Login from "./components/Login/Login";
import "./captcha/captcha";

const loginDiv = document.getElementById('login-lbk');
const rootDiv = document.getElementById('root-lbk');
const highlightedProductsDiv = document.getElementById('highlighted-products-root-lbk');
if (loginDiv) {
    const root = ReactDOM.createRoot(loginDiv);
    root.render(
        <Provider store={store}>
            <ReactKeycloakProvider authClient={keycloak}>
                <Login/>
            </ReactKeycloakProvider>
        </Provider>
    );
}else console.log("react login container not found.");

if (rootDiv) {
    const root = ReactDOM.createRoot(rootDiv);
    const popularProducts = rootDiv.getAttribute("popularProducts");
    root.render(
        <Provider store={store}>
            <App popularProducts={popularProducts}/>
        </Provider>
    );
}else console.log("react root container not found.");

if (highlightedProductsDiv) {
    const root = ReactDOM.createRoot(highlightedProductsDiv);
    const filters= highlightedProductsDiv.getAttribute("filterParams");
    root.render(
        <Provider store={store}>
            <HighlightedProductsWidget filters={filters}/>
        </Provider>
    );
}else console.log("react highlighted content container not found.");



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
