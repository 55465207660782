
import {RadioGroup,} from '@headlessui/react';
import {CheckCircleIcon} from "@heroicons/react/24/solid";
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
function DeliveryMethods(props) {

    const {deliveryMethods,selectedDeliveryMethod,setSelectedDeliveryMethod,isExtraDeliveryTimeRequired} = props;

    return (
        <div className="mt-10 border-t border-gray-200 py-10">
            <RadioGroup value={selectedDeliveryMethod} onChange={setSelectedDeliveryMethod}>
                <RadioGroup.Label className="text-lg font-medium text-gray-900">Leverings</RadioGroup.Label>

                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                    {deliveryMethods.map((deliveryMethod) => (
                        <RadioGroup.Option
                            key={deliveryMethod.id}
                            value={deliveryMethod}
                            className={({ checked, active }) =>
                                classNames(
                                    checked ? 'border-transparent' : 'border-gray-300',
                                    active ? 'ring-2 ring-lbkGreen' : '',
                                    'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                                )
                            }
                        >
                            {({ checked, active }) => (
                                <>
                            <span className="flex flex-1">
                              <span className="flex flex-col">
                                <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                  {deliveryMethod.name} - {deliveryMethod.deliveryType}
                                </RadioGroup.Label>
                                <RadioGroup.Description
                                    as="span"
                                    className="mt-1 flex items-center text-sm text-gray-500"
                                >
                                  Estimert leveringstid: {isExtraDeliveryTimeRequired?
                                    <span className={"px-2"}> 3 - {Number(deliveryMethod.workingDays) + 7} dager +</span>:
                                    <span className={"px-2"}> 1 - {Number(deliveryMethod.workingDays) + 1} dager</span>}
                                </RadioGroup.Description>
                                <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                                  Kr {Number(deliveryMethod.amountWithVat).toLocaleString("nb")}
                                </RadioGroup.Description>
                              </span>
                            </span>
                                <span className={"h-full flex items-center"}><img className={"w-20"} src={deliveryMethod.logoUrl} alt=""/></span>
                                    {checked ? (
                                        <CheckCircleIcon className="h-5 w-5 text-lbkGreen" aria-hidden="true" />
                                    ) : null}
                                    <span
                                        className={classNames(
                                            active ? 'border' : 'border-2',
                                            checked ? 'border-lbkGreen' : 'border-transparent',
                                            'pointer-events-none absolute -inset-px rounded-lg'
                                        )}
                                        aria-hidden="true"
                                    />
                                </>
                            )}
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
        </div>
    )
}
export default DeliveryMethods