import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import React, {Fragment} from "react";
import {getKeycloakToken, storeKeycloakToken} from "../../utils";
import {connect} from "react-redux";



function LoginProfile(props) {

    let userInfo = props.userInfo;

    function getNameSign(name) {
        let sign = "";
        if (name.length > 0) {
            name.split(" ").map(n => sign = sign + n.slice(0, 1))
        }
        return sign;

    }
    if (userInfo && userInfo.id)
        return (
            <Menu as="div" className="ml-3 relative max-w-[20rem]">
                <div>
                    <Menu.Button
                        className="max-w-xs flex items-center text-sm focus:outline-none focus:ring-0 ring-0 lg:p-2 border-b border-b-lbkGreen">
                        <span
                            className={"rounded-full h-10 w-10 bg-lbkGreen text-center text-xs flex items-center justify-center"}>{getNameSign(`${userInfo.firstName} ${userInfo.lastName}`)}</span>
                        <span
                            className="hidden ml-3 text-lbkGreen text-sm font-medium lg:block">{userInfo.firstName} {userInfo.lastName}</span>
                        <ChevronDownIcon
                            className="hidden flex-shrink-0 ml-1 h-5 w-5 text-lbkGreen lg:block"
                            aria-hidden="true"
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <ul
                        className="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-2xl py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <li className={"w-full"}>
                            <a className={"text-center hover:bg-lbkGreen hover:text-black block px-4 py-2 text-sm text-gray-700 cursor-pointer"}
                               href={`/shop/mypage/${userInfo.id}`}>Min side</a>
                        </li>

                        <li className={"w-full"}>
                            <button onClick={() => {
                                props.keycloak.logout({redirectUri: process.env.REACT_APP_HOME});
                                storeKeycloakToken("");
                            }}
                                    className={'block hover:bg-lbkGreen hover:text-black w-full px-4 py-2 text-sm text-gray-700 cursor-pointer'}
                            >
                                Logout
                            </button>
                        </li>
                    </ul>
                </Transition>
            </Menu>
        )
    return <div className={"ml-3 relative max-w-[20rem] text-lbkGreen text-xs animate-ping h-10"}>loading</div>
}
const mapStateToProps = ({userInfo}) => {
  return {userInfo:userInfo}
}
export default connect(mapStateToProps,{})(LoginProfile);